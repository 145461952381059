<template>
  <el-container class="index-box" style="display: block;">
    <!-- <div
      class="propaganda hidden-xs-only"
      v-if="pro && user.roleId !== 100"
      @click="getLink"
      id="propaganda"
      v-html="sys_advertising"
    >
    </div> -->
    <div class="conBox">
      <el-container class="aside">
        <el-aside
          width="200px"
          :class="stute ? 'hidden-sm-and-up' : 'hidden-xs-only'"
        >
         <div class="header-box-logo fx-v-center hidden-xs-only">
           <img alt="Midone Tailwind HTML Admin Template" class="w-6" src="../../assets/img/logo.svg">

            <!-- <div class="header-box-logo-name ml-3">{{ merchantName }}后台</div> -->
            <div class="header-box-logo-name ml-3">灵西查询</div>

          <!-- <el-divider direction="vertical" class="line"></el-divider>
          <span class="ft-sz-16">商户管理</span> -->
        </div>
          <menus
            :isCollapse="collapse"
            :menu-list="menuList"
            :isNew="newRecord"
            @cancel="stute = false"
          />
        </el-aside>
        <el-container class="table-wrap">
          <el-header height="70px" class="hidden-xs-only bg70">
            <Headers @switch-collapse="collapse = !collapse" />
          </el-header>
          <div class="indextop hidden-sm-and-up">
            <span @click="show()"><img src="@/assets/h5img/nav.png" alt="" /></span>
            <!-- {{ merchantName }} -->

            <el-header>
              <Headers @switch-collapse="collapse = !collapse" />
            </el-header>
          </div>
          <el-main>
            <router-view v-if="isRouterAlive" />
          </el-main>
        </el-container>
        <!-- 客服二维码 -->
        <div
          class="fuwu kefu"
          @click="showKefu = true"
          v-if="wechat || imageUrl"
        >
          <span>官方客服</span>
        </div>
        <!-- 新手引导 -->
        <!-- <router-link tag="div" to="/manual" v-if="!isChannel">
          <a class="fuwu manual" target="_blank">
            <img src="../../assets/img/manual.png" />
            <span>新手引导</span>
          </a>
        </router-link> -->
      </el-container>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
      custom-class="tips"
      center
      show-close="false"
    >
      <div class="tipsTop">
        尊敬的商户您好，为了让您稳定的使用本系统，商户后台登陆网址已经更换，
        <div>旧网址即将失效，请尽快更换新的网址</div>
      </div>
      <div class="address">新的登陆后台：sh.fanglu6.com</div>

      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button class="dialogBtn" type="primary" @click="close"
          >我已知晓</el-button
        >
      </span>
    </el-dialog>
    <!--    客服二维码-->
    <el-dialog
      :visible.sync="showKefu"
      :width="wechatWidth"
      top="30vh"
      center
      show-close="true"
      custom-class="kefu-ewm"
      :modal-append-to-body="false"
    >
      <div>
        <p class="ft-sz-18 ft-wt-bold" v-if="wechat">
          官方客服微信号：{{ this.wechat }}
        </p>
        <div class="pic" v-if="imageUrl">
          <span>
            <img :src="imageUrl" />
          </span>
        </div>
      </div>
    </el-dialog>
    <!-- 重要通知 -->
    <!-- <el-dialog
      :visible.sync="noticeTips"
      width="60%"
      center
      show-close="false"
      custom-class="tips"
      :modal-append-to-body="false"
    >
      <div class="tipsTop">
        <div class="title">重要通知</div>
        <div>系统名称和app名称已换，新的app名称为："快客"</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="dialogBtn" type="primary" @click="closeTip"
          >我已知晓</el-button
        >
      </span>
    </el-dialog> -->
    <!-- 余额提示 -->
    <el-dialog
      :visible.sync="balanceTips"
      width="60%"
      center
      show-close="false"
      custom-class="tips"
      :modal-append-to-body="false"
    >
      <div class="tipsTop">
        尊敬的商户您好，您的账户余额已不足100元，请尽快安排充值，以免影响正常使用！
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="balanceTips = false">取 消</el-button> -->
        <el-button class="dialogBtn" type="primary" @click="closeBalance"
          >我已知晓</el-button
        >
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import Menus from "@/components/menus/menus";
import Headers from "@/components/header/header";

import service from "./api";
import MENU from "./MENU";
import clone from "../../utils/clone";
import report from "@/mixins/getReports";
import { base } from "@/api/env";
export default {
  mixins: [report],
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      collapse: false,
      isRouterAlive: true,
      user: { name: "", mobile: "" },
      menuList: [],
      dialogVisible: false,
      isPreview: false,
      stute: false,
      sys_advertising: null,
      wechat: "",
      imageUrl: "",
      showKefu: false,
      wechatWidth: "20%",
      noticeTips: false,
      balanceTips: false,
    };
  },
  components: {
    Menus,
    Headers,
  },
  computed: {
    // asideWidth() {
    //   return this.collapse ? "auto" : "200px";
    // },
    // 余额
    balance() {
      return this.$store.state.balance || 0;
    },
    promoteCoin() {
      return this.$store.state.promoteCoin || 0;
    },
    // 面包屑导航
    breadList() {
      let defaultName = sessionStorage.getItem("merchant_name");
      let list = [{ path: "/", name: defaultName }].concat(
        this.$route.meta.bread
      );
      return this.$route.meta.bread ? list : [];
    },
    isChannel() {
      return this.$store.state.XINYOU_SH_USER.roleId === 100;
    },
    merchantName() {
      let name = sessionStorage.getItem("merchant_name");
      return name || "商户";
    },
    newRecord() {
      return this.$store.state.newRecord;
    },
  },
  methods: {
    //广告
    getAdvertise() {
      service.getAdvertise().then((res) => {
        this.sys_advertising = res.sys_advertising;
      });
    },
    showMoney() {
      let a = this.balance > -999 && !this.isChannel;
      this.$alert(
        '<div class="user-right ft-sz-14" v-if="' +
          a +
          '">' +
          '<div class="default user-right-balance">账户余额 ' +
          this.balance +
          "元</div>" +
          '<div class="default user-right-balance" v-if="' +
          this.pro +
          '">剩余星币 ' +
          this.promoteCoin +
          "个</div>" +
          "</div>",
        "余额及充值",
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: "充值",
        }
      ).then(() => {
        this.toCharge();
      });
    },
    show() {
      this.stute = !this.stute;
    },
    close() {
      sessionStorage.setItem("tips", false);
      window.location = "https://client.lingxi.group";
      this.dialogVisible = false;
    },
    handleClose(done) {},
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    // 获取子账号权限
    getMenu(id) {
      return service.getMenu({ sonId: id }).then((res) => {
        let { jurisdiction = [] } = res[0];
        console.log(JSON.stringify(res[0]))
        // 拷贝菜单
        let copyMenu = clone.deep(MENU.list);
        console.log(JSON.stringify(MENU.list))
        let list = copyMenu.filter((item, index) => {
          if (jurisdiction.some((el) => el.name === item.name)) {
            // 匹配到父菜单
            if (!item.hasOwnProperty("children")) {
              //  没有子菜单直接返回
              return item;
            } else {
              // 过滤子菜单
              item.children = item.children.filter((child) =>
                jurisdiction.some((el) => el.name === child.name)
              );
              return item;
            }
          }
        });
        this.menuList = [copyMenu[0]].concat(list);
        console.log('menuList',this.menuList)
      });
    },
    // 充值
    toCharge() {
      this.$router.push({ path: "/charge" });
    },
    getLink() {
      this.$router.push({ path: "/promote/setting" });
    },
    //判断浏览器
    getExplorer() {
      let explorer = window.navigator.userAgent;
      if (
        explorer.toLowerCase().indexOf("se 2.x") > -1 ||
        (explorer.indexOf("Safari") > -1 && explorer.indexOf("Chrome") == -1)
      ) {
        document.getElementsByClassName("el-main")[0].style.borderRadius = "0";
      }
    },
    //是否有新的更新记录
    getNewReord() {
      return service.getNewRecord().then((res) => {
        this.newRecord = res.isVer;
        this.$store.commit("setNewRecord", res.isVer);
      });
    },
    //获取客服联系方式
    getContact() {
      return service.getContact().then((res) => {
        this.wechat = res.sponsoredUserWechat;
        this.imageUrl = res.sponsoredUserWechatQrcode
          ? base + "api/common/attach/" + res.sponsoredUserWechatQrcode
          : "";
      });
    },
    //关闭重要通知
    closeTip() {
      this.noticeTips = false;
      sessionStorage.setItem("noticeTips", false);
    },
    //关闭余额提示
    closeBalance() {
      this.balanceTips = false;
      sessionStorage.setItem("baranceTips", false);
    },
  },
  created() {
    // 判断url是否以preview开头
    console.log(
      "url",
      location.hostname,
      location.hostname.indexOf("preview") != -1
    );
    this.isPreview = location.hostname.indexOf("preview") != -1;
    // 获取身份信息
    let user = JSON.parse(sessionStorage.getItem("xinyou_sh_user")) || {};
    this.$store.commit("setUser", user);
    let { name = "", mobile = "", id = null } = user;
    this.user = user || { name: "", mobile: "" };
    this.dialogVisible =
      sessionStorage.getItem("tips") == "true" ? true : false;
    // 获取剩余报告数
    this.getReports();
    this.getMenu(id);
    if (document.documentElement.clientWidth < 480) {
      this.wechatWidth = "80%";
    }
    //获取客服联系方式
    this.getContact();
    //重要通知显示
    this.noticeTips =
      this.pro && sessionStorage.getItem("noticeTips") == "true" ? true : false;
    //余额提示
    this.balanceTips =
      this.pro && sessionStorage.getItem("baranceTips") == "true"
        ? true
        : false;
  },
  mounted() {
    this.getExplorer();
    this.getAdvertise();
    this.getNewReord();
  },
};
</script>

<style lang="scss">
.header-box{
  padding: 0 !important;
}
.hidden-sm-and-up{
  background-color: #fff;
  // height: 50px;
}
.hidden-sm-and-up span{
  margin-left: 30px;
}
.bg70{
  // background: linear-gradient(120deg, #0f75ff 60%, #2ddcd3 100%);
  background:  #fff;
  color:#000;
  width: 100%;
}
.index-box {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  .propaganda {
    background: #cc0033;
    padding: 0 60px 0 80px;
    text-align: left;
    line-height: 80px;
    .left {
      float: left;
    }
    span {
      color: #ffc513;
      font-size: 26px;
      font-weight: 700;
      margin: 0 30px 0 80px;
    }
    .word1 {
      font-weight: 500;
      font-size: 24px;

      color: #ffffff;
      margin-right: 60px;
      margin-left: 0;
    }
    .word2 {
      font-weight: 500;
      font-size: 16px;
      color: #ffffff;
      margin: 0;
    }
    .proBtn {
      float: right;
      color: #fff;
      background: #f5854f;
      height: 35px;
      line-height: 35px;
      padding: 0 35px;
      border-radius: 20px;
      margin-top: 22px;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .el-header {
   // background-color: #fff;
    // margin-left: 10px;
    margin-bottom: 20px;
    margin-right: 15px;
  }
  .el-container {
    // margin-top: -40px;
    //padding: 0 60px 0 80px;
    box-sizing: border-box;
    .el-aside {
      min-height: calc(100vh - 190px);
      overflow: hidden;
      // padding-right: 20px;
      // padding-left: 20px;
      background-color: #545c64;
    }
    .el-main {
      margin-left: 10px;
      z-index: 100;
      border-radius: 3px;
      padding: 20px;
      margin-right: 10px;
    }
    .table-wrap {
      position: relative;
      &.el-container {
        margin-top: 0;
        padding: 0;
        //padding-bottom: 100px;
      }
      //.el-main{
      //  border-radius: 0;
      //}
    }
  }

  .conBox {
    min-height: calc(100vh - 70px);
    flex: auto;
    // background-color: #2e51bb;
    .user {
      width: 100%;
      height: 100px;
      // padding: 20px 80px 40px;
      background: #059742;
      text-align: left;
      box-sizing: border-box;
      &-left {
        max-width: 190px;
        padding: 0 20px;
        overflow: auto;
        color: #ffffff;
        text-align: left;
        white-space: nowrap;
      }
      &-center {
        // margin-left: 270px;
        color: #fff;
        position: absolute;
        &-title {
          font-size: 20px;
          font-weight: 500;
          span {
            margin-left: 10px;
          }
        }
        &-bread {
          //margin-left: 20px;
          //margin-top: 10px;
          .el-breadcrumb__inner {
            color: #fff;
            font-weight: 500;
            font-size: 15px;
            cursor: pointer;
          }
        }
      }
      &-right {
        margin-left: auto;
        .default {
          height: 36px;
          line-height: 36px;
          border-radius: 5px;
          padding: 0 20px;
          cursor: pointer;
          display: inline-block;
        }
        .charge-btn {
          background-color: #f93b7a;
          border: 1px solid #f93b7a;
          margin-right: 20px;
          color: #ffffff;
        }
        &-balance {
          background-color: #e2e2f1;
          border: 1px solid #e2e2f1;
          color: #fff;
          margin-right: 10px;
        }
      }
    }
  }
  .tips {
    .tipsTop {
      font-size: 24px;
      text-align: center;
      color: #ba5655;
      margin-bottom: 30px;
      .title {
        font-size: 30px;
      }
      div {
        margin-top: 10px;
      }
    }

    .address {
      font-size: 30px;
      text-align: center;
      color: #ba5655;
      font-weight: 700;
    }

    .dialogBtn {
      background: #ba5655;
      border: none;
      font-size: 18px;
      font-weight: 700;
    }

    .el-button--primary:focus,
    .el-button--primary:hover {
      background: #ba5655;
      border: none;
      font-size: 18px;
    }

    .el-dialog__headerbtn {
      display: none;
    }

    .el-dialog {
      display: flex;
      flex-direction: column;
      margin: 0 !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      /*height:600px;*/
      max-height: calc(100% - 30px);
      max-width: calc(100% - 30px);
    }

    .el-dialog .el-dialog__body {
      flex: 1;
      overflow: auto;
    }
  }
  .fuwu {
    position: fixed;
    bottom: 400px;
    right: 4px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    cursor: pointer;
    z-index: 200;
    span {
      font-size: 12px;
      width: 100%;
      position: absolute;
      bottom: -20px;
      left: 0;
      color: #2c3e50;
    }
  }
  .kefu {
    background: url("../../assets/img/kefu.png") no-repeat;
    background-size: cover;
    bottom: 500px;
  }
  .manual {
    bottom: 400px;
    background-color: #fbb624;
    img {
      width: 30px;
      height: 30px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.kefu-ewm {
  text-align: center;
  p {
    color: black;
    text-align: center;
    margin-bottom: 10px;
  }
  .pic {
    text-align: center;
    span {
      display: inline-block;
      border: 5px solid #f0eeec;
      width: 200px;
      height: 200px;
      line-height: 200px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .hidden-sm-and-up span{
    margin-left: 0!important;
  }
  .indextop {
    background-color: #fff;
    padding-left: 30px;
    height: 50px;
    span {
      display: inline-block;
      img {
        width: 30px;
        height: 30px;
        top: 10px;
        position: relative;
        display: flex;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .hidden-xs-only {
    display: none !important;
  }
  * {
    box-sizing: border-box;
  }
  .indextop {
    width: 100%;
    min-height: 3rem;
    line-height: 3rem;
    background: rgba(255, 255, 255, 1);
    color: #2c66b2;
    font-size: 22px;
    .header-box-drop {
      padding-right: 1rem;
    line-height: 1rem;
    position: absolute;
    right: 20px;
    top: 5px;
    }
    span {
      float: left;
    margin-left: 20px;
    line-height: 1rem;
    margin-right: 10px;
      img {
        width: 1.5rem;
        height: 1.5rem;
        line-height: 2rem;
        // margin-top: 0.8rem;
      }
    }
    .person {
      float: right;
      line-height: auto;
      margin-right: 0;
    }
  }
  .aside {
    padding: 0 !important;
    margin-top: 0 !important;
    position: relative;
    .el-aside {
      position: absolute;
      z-index: 1000;
      width: 50%;
      top: 3rem;
    }
  }
  .el-main {
    margin-left: 0 !important;
  }
  .index-box .el-container .table-wrap.el-container {
    padding-bottom: 0;
  }
  .index-box .conBox .user-center {
    margin-left: 0;
    left: 0;
    height: 2rem;
    width: 100%;
  }

  .index-box .conBox .user {
    height: 3rem;
    padding: 20px 80px;
    padding-right: 0;
  }
  .index-box .el-header {
    display: inline;
    padding: 0 !important;
    background-color:#000;
    .header-box {
      display: inline-block;
      padding: 0 !important;
      .person {
        display: inline;
      }
    }
  }
  .money {
    position: absolute;
    right: 1.25rem;
    top: 0.5rem;
    img {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  .el-message-box {
    width: 80% !important;
  }
  // .el-message-box__btns .el-button--primary{
  //   display: block!important;
  //   width: 4rem!important;
  //   height: 2.2rem!important;
  //   margin: 1rem auto!important;
  //   color: #ffffff!important;
  //   text-align: center!important;
  //   background: #F93B7A!important;
  //   border-radius: 0.2rem!important;
  //   border: none!important;
  // }
  .user-right-balance {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    padding-top: 0.5rem;
    color: #000000;
  }
  .user-right-balance:first-child {
    border-top: 0.0825rem solid #e2e2f1;
    padding-top: 1.5rem;
  }
}
@media screen and (max-width: 480px) {
  .kefu {
    bottom: 400px !important;
  }
  .manual {
    bottom: 300px !important;
  }
}
.header-box-logo{
    color: #fff;
    width: 220px;
    border-top-left-radius: 3px;
    padding-left: 20px;
    font-size: 1.25rem;
    font-weight: 400;
        height: 70px;
    line-height: 70px;
    background-color: #545c64;
    //border-bottom: rgba(46, 81, 187);
    border-bottom:1px solid #545c64
}
.w-6 {
    width: 1.5rem;
}
.ml-3 {
    margin-left: 0.75rem;
}
</style>
