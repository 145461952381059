<template>
  <div class="header-box fx-v-center" style="justify-content: space-between; ">
    <div class="fx-v-center hidden-xs-only">
      <el-breadcrumb v-if="breadList.length">
         <el-breadcrumb-item
            v-for="(item, index) in breadList"
            :key="index"
            :to="{ path: item.path }"
            >{{ item.name }}</el-breadcrumb-item
          >
      </el-breadcrumb>
      <!-- <el-breadcrumb
        separator-class="el-icon-arrow-right"
        class="user-center-bread"
         v-if="breadList.length"
         style="color: #000000"
      >
        <el-breadcrumb-item
          v-for="(item, index) in breadList"
          :key="index"
          :to="{ path: item.path }"
          >{{ item.name }}</el-breadcrumb-item
        >
      </el-breadcrumb> -->
    </div>
    <!-- <div class="header-box-fold hidden-xs-only" >
      <span class="ft-sz-22 el-icon-s-fold" title="折叠" @click="$emit('switch-collapse')"></span>
    </div> -->
    <!-- <div class="tip hidden-xs-only" v-if="balance < 100 && user.roleId !== 100">温馨提示:您的账户余额已不足100元,请尽快安排充值,以免影响使用!</div> -->
    <!-- 头像下拉表 -->
    <div style="display: flex; align-items: center;">
      
      <div>
        <!-- <div class="user-left hidden-xs-only">
        <span class="ft-bold ft-sz-18">你好, {{ user.username || "" }}</span>
        <p>{{ user.mobile || "************" }}</p>
      </div> -->
        <!-- <span class="money hidden-sm-and-up" @click="showMoney()"
            ><img src="@/assets/h5img/money.png" alt=""
          /></span> -->
        <div
          class="ft-sz-15 hidden-xs-only"
          v-if="balance > -999 && !isChannel"
          style="display: flex; align-items: center;"
        >
          <div class="default" style="margin-left: 25px; margin-right: 20px;color:#000">
            账户余额 {{ balance }} 元
          </div>
          <!-- <div class="default" v-if="pro" style="margin-left: 25px">
            剩余星币 {{ promoteCoin }} 个
          </div>
          <div
            class="default tips"
            @click="toCharge"
            v-if="!isPreview"
            >充值</div> -->
        </div>
      </div>

      <div class="header-box-drop">
        <el-dropdown trigger="hover" class="user-name" @command="handleCommand">
          <div class="avatar fx-v-center ft-sz-15" style="color: #000;">
            <span class="hidden-xs-only">个人中心</span>
            <i class="el-icon-arrow-down el-icon--right hidden-xs-only"></i>
            <span class="person hidden-sm-and-up"><img src="@/assets/h5img/penson.png" alt=""></span>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="reset">账户信息</el-dropdown-item>
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
    </div>
    </div>
    <!-- <div class="tip ft-bold hidden-sm-and-up" v-if="balance < 100 && user.roleId !== 100">温馨提示:您的账户余额已不足100元,请尽快安排充值,以免影响使用!</div> -->
    <el-dialog title="账户信息" :width="width" :visible.sync="dialogFormVisible" :close-on-click-modal="false" @close="close">
      <el-form class="form" ref="form" :model="form" :rules="rules" label-width="90px">
        <el-form-item label="商户" prop="merchantName">
          <el-input v-model.trim="form.merchantName" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item label="账户" prop="account">
          <el-input v-model.trim="form.account" autocomplete="off"></el-input>
        </el-form-item>
        <!-- <el-form-item label="手机号" prop="mobile">
          <el-input v-model="form.mobile" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item class="code" prop="code" label="验证码">
          <el-input v-model.trim="form.code" placeholder="短信验证码"></el-input>
          <el-button
            type="primary"
            class="code-btn"
            :disabled="!!seconds || !form.mobile"
            @click="onGetCodeButtonClick"
          >{{codeBtn}}</el-button>
        </el-form-item> -->
        <el-form-item label="新密码" prop="password">
          <el-input v-model="form.password" autocomplete="off" placeholder="留空则不修改密码" type="password"  show-password></el-input>
        </el-form-item>
        <el-form-item label="请重复密码" prop="re_password">
          <el-input v-model="form.re_password" autocomplete="off" placeholder="请重复密码" type="password"  show-password></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitReset">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import service from "./api";
import {Message, MessageBox} from "element-ui";

import code from "../../views/login/mixins.js";
import gt from "@/mixins/geetest";

export default {
  mixins: [code],
  computed: {
    merchantName() {
      let name = sessionStorage.getItem("merchant_name");
      return name || "商户";
    },
    // 余额
    balance() {
      return this.$store.state.balance || 0;
    },
    user(){
      return this.$store.state.XINYOU_SH_USER || {};
    },
    isChannel() {
      return this.$store.state.XINYOU_SH_USER.roleId === 100;
    },
    promoteCoin() {
      return this.$store.state.promoteCoin || 0;
    },
    // 面包屑导航
    breadList() {
      let defaultName = sessionStorage.getItem("merchant_name");
      let list = [{ path: "/", name: defaultName }].concat(
        this.$route.meta.bread
      );
      return this.$route.meta.bread ? list : [];
    }
  },
  data() {
    var checkPsd = (rule, value, callback) => {
      if (this.form.re_password !== this.form.re_password) {
        this.$refs.form.validateField("re_password");
      }
      callback();
    };
    var checkRepsd = (rule, value, callback) => {
      if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      isPreview: false,
      dialogFormVisible: false,
      formLabelWidth: "120px",
      form: {
        account: "",
        mobile: "",
        password: "",
        re_password: "",
        code: "",
      },
      seconds: 0,
      rules: {
        account: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            pattern: /^[a-zA-z0-9]\w{3,15}$/,
            message:
              "账号应为4-16位字符，可含数字、字母、下划线",
            trigger: "blur",
          },
        ],
        // mobile: [
        //   { required: true, message: "请输入手机号", trigger: "blur" },
        //   {
        //     pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
        //     message: "请输入正确格式的手机号",
        //     trigger: "blur",
        //   },
        // ],
        password: [
          { validator: checkPsd, trigger: "blur" },
          {
            pattern: /^[a-zA-z0-9]\w{5,19}$/,
            message: "6-20位字符，由数字、字母、下划线组成，且不能以下划线开头",
            trigger: "blur",
          },
        ],
        re_password: [{ validator: checkRepsd, trigger: "blur" }],
      },
      width:'500px',
      captcha: false,
    };

  },
  methods: {
    handleCommand(command) {
      switch (command) {
        case "logout":
          // sessionStorage.removeItem('xinyou_sh_user');
          // sessionStorage.removeItem('token');
          sessionStorage.clear();
          this.$router.replace({ path: "/login" });
          break;
        case "reset":
          this.openReset();
          break;
        case "money":
          this.
          break;
      }
    },
    openReset() {
      let promise = new Promise((resolve, reject) => resolve());

      service.getAccountInfo().then((res) => {
        this.form.account = res.account;
        this.form.mobile = res.mobile;
        this.form.merchantName = res.merchantName;
        console.log('getAccountInfo',res)
        this.dialogFormVisible = true;
      });

      promise.then(async () => {
        await this.initGt();
      })

    },
    async submitReset() {
      // 校验
      const validatePass = await this.$refs["form"].validate();
      if (!validatePass) return;

      // 提示确认信息
      const confirmYes = await MessageBox.alert(
        `本操作不可还原，确定您输入的信息输入无误吗？`,
        "警告",
        { showCancelButton: true }
      )
        .then(() => true)
        .catch(() => false);
      if (!confirmYes) return;

      // 保存信息
      service.saveAccountInfo(this.form).then((_) => {
        sessionStorage.clear();
        this.dialogFormVisible = false;

        Message.success("修改成功, 即将重新登录");
        setTimeout(() => {
          this.$router.replace({ path: "/login" });
        }, 2000);
      });
    },
    // 点击图标回首页
    toIndex() {
      this.$router.push({ path: "/" });
    },
    close(){
      this.dialogFormVisible = false;
      this.$refs.form.resetFields();
    },
    //极验
    initGt() {
      return gt.register().then(captcha => {
        this.captcha = captcha;
        return captcha;
      })
    },
    onGetCodeButtonClick() {
      console.log("开始发送")
      this.captcha.onReady(function () {
      }).onSuccess(() => {
        let result = this.captcha.getValidate();
        console.log(result);
        if (!result)
          return;
        // 发送验证码
        let {mobile = ""} = this.form;
        if (this.seconds > 0) return;
        clearTimeout(this.timer);
        service.getCode({phone: mobile, ...result}).then(() => {
          this.till = new Date().getTime() + 60 * 1000;
          this.countDown();
        });
      });
      this.captcha.verify();
    },
    toCharge() {
      this.$router.push({ path: '/charge' });
    },
    showMoney() {
      let a = this.balance > -999 && !this.isChannel;
      this.$alert(
        '<div class="user-right ft-sz-14" v-if="' +
          a +
          '">' +
          '<div class="default user-right-balance">账户余额 ' +
          this.balance +
          "元</div>" +
          '<div class="default user-right-balance" v-if="' +
          this.pro +
          '">剩余星币 ' +
          this.promoteCoin +
          "个</div>" +
          "</div>",
        "余额及充值",
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: "充值",
        }
      ).then(() => {
        this.toCharge();
      });
    },
  },
  created(){
    if (document.documentElement.clientWidth < 480) {
      this.width = '80%'
    }
    this.isPreview = location.hostname.indexOf("preview") != -1;
  }
};
</script>

<style lang="scss" scoped>
.header-box-drop{
  color: #000;
  position: relative;
    right: 10px;
    z-index: 999;
    top: 0;
    margin-left: 30px;
}

.hidden-sm-and-up{
  display: block;
  background-color: #fff;
}
.hidden-sm-and-up span{
  margin-left: 30px;
}

.header-box {
  // height: 70px;
  padding: 0 60px;
  // border-bottom: 1px solid #e2e8f0;
  box-sizing: border-box;
  //background-color: #fff;
  line-height: 70px;
  &-logo {
    min-width: 250px;
    margin-right: 20px;
    &-name {
      color: #2b65b1;
      margin-left: 20px;
      letter-spacing: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .line {
      margin-right: 10px;
    }
  }
  &-fold {
    margin-left: 10px;
  }
  .tip {
    color: #fff;
    margin-left: 8px;
    font-size: 15px;
  }
   .tips {
    color: red;
    margin: 0 25px;
    cursor: pointer;
    font-weight: bold;
  }
  .default{
    // font-weight: bold;
  }
  .chongzhi{
    height: 36px;
    line-height: 36px;
    border-radius: 5px;
    padding: 0 20px;
    cursor: pointer;
    display: inline-block;
    background-color: #f93b7a;
    border: 1px solid #f93b7a;
    margin-right: 10px;
    color: #ffffff;
    margin-left: 10px;
  }
}
.form {
  width: 90%;
  margin: 0 auto;
  text-align: left;
}
.el-form-item {
  margin-bottom: 32px;
}
.code {
  .el-input {
    width: 68%;
    margin-right: 3%;
  }
  &-btn {
    width: 28%;
    min-width: 85px;
    padding: 12px 10px;
  }
}
.w-6 {
    width: 1.5rem;
}
@media screen and (max-width: 767px) {
  .header-box-drop{
    position: absolute;
    right: 25px;
    z-index: 999;
    margin-top: -8px;
  }
  .indextop {
    background-color: #fff;
    padding-left: 30px;
    
    span {
      display: inline-block;
      img {
        width: 30px;
        height: 30px;
      }
    }  
  }
}
@media screen and (max-width: 480px) {
  .header-box{
    line-height: 3rem;
  }
  .header-box .tip{
    font-size: 10px;
    height: auto;
    line-height: 1rem;
    width: 100%;
    background: #fe0101;
    color: #fff;
    margin-left: 0;
    padding: 0.3rem 1rem;
  }
}
</style>
