const list = [{
    name: "控制台",
    path: "/control",
    index: "1",
    icon: "el-icon-s-platform"
  },
  // {
  //   name: "用户管理",
  //   index: "2",
  //   icon: "el-icon-s-custom",
  //   path: "",
  //   children: [{
  //       name: "所有用户",
  //       path: "/allUsers",
  //       index: "2-1"
  //     },
  //     {
  //       name: "未处理",
  //       path: "/users",
  //       index: "2-2"
  //     },
  //     {
  //       name: "已通过",
  //       path: "/passList",
  //       index: "2-3"
  //     },
  //     {
  //       name: "已拒绝",
  //       path: "/refuseList",
  //       index: "2-4"
  //     },
  //     {
  //       name: "黑名单",
  //       path: "/blacklist",
  //       index: "2-5"
  //     }
  //   ]
  // },
  {
    name: "订单管理",
    index: "3",
    path: "",
    icon: "el-icon-s-order",
    children: [{
        name: "初审列表",
        path: "/order/firstCheck",
        index: "3-1"
      },
      {
        name: "复审列表",
        path: "/order/secondCheck",
        index: "3-2"
      },
      {
        name: "待还列表",
        path: "/order/unpaid",
        index: "3-3"
      },
      {
        name: "回款记录",
        path: "/order/paid",
        index: "3-4"
      },
      {
        name: "已拒订单",
        path: "/order/refused",
        index: "3-5"
      },
      {
        name: "续期记录",
        path: "/order/renewal",
        index: "3-6"
      }
    ]
  },
  // {
  //   name: "查询列表",
  //   index: "2",
  //   path: "/risk",
  //   // icon: "el-icon-s-marketing",
  //   icon: "el-icon-s-platform"
  //   // children: [{
  //   //   name: "查询列表",
  //   //   path: "/risk",
  //   //   index: "4-1"
  //   // },{
  //   //   name: "查询详情",
  //   //   path: "/risk/detail",
  //   //   index: "4-2"
  //   // }]
  // },
  {
    name: "快速查询",
    index: "4",
    path: "",
    icon: "el-icon-s-management",
    children: [
      {
        name: "查询列表",
        path: "/risk",
        index: "4-1"
      },
      {
        name: "JT多平台",
        path: "/jtrisk",
        index: "4-2"
      },
      {
        name: "真假财务",
        path: "/financeReally",
        index: "4-3"
      },
      {
        name: "客户黑名单",
        path: "/merchantBlackList",
        index: "4-4"
      }
    ]
  },

  {
    name: "消费统计",
    index: "3",
    path: "",
    icon: "el-icon-s-finance",
    children: [{
        // name: "充值记录",
        // path: "/finance/purchase",
        // index: "4-1"
      },
      {
        name: "查询明细",
        path: "/finance/detail",
        index: "4-2"
      },
      {
        name: "消费统计",
        path: "/finance/consume",
        index: "4-3"
      },
    ]
  },
  {
    name: "账号管理",
    index: "5",
    path: "",
    icon: "el-icon-basketball",
    children: [{
        name: "角色管理",
        path: "/userManager",
        index: "5-1"
      },
      {
        name: "账号列表",
        path: "/account",
        index: "5-2"
      }
    ]
  },


  // {
  //   name: "渠道推广",
  //   index: "7",
  //   path: "",
  //   icon: "el-icon-share",
  //   children: [{
  //       name: "我要推广",
  //       path: "/promote/setting",
  //       index: "7-1"
  //     },
  //     {
  //       name: "推广链接",
  //       path: "/link",
  //       index: "7-2"
  //     },
  //     {
  //       name: "推广统计",
  //       path: "/statistics",
  //       index: "7-3"
  //     }
  //   ]
  // },

  // {
  //   name: "系统设置",
  //   index: "8",
  //   path: "",
  //   icon: "el-icon-s-tools",
  //   children: [{
  //       // name: "关键词",
  //       // path: "/setting/keyWords",
  //       // index: "8-1"
  //     },
  //     {
  //       name: "意见反馈",
  //       path: "/setting/feedBack",
  //       index: "8-2"
  //     },
  //     {
  //       // name: "基础设置",
  //       // path: "/setting/registerTitle",
  //       // index: "8-3"
  //     },
  //     {
  //       name: "产品设置",
  //       path: "/setting/productSet",
  //       index: "8-4"
  //     },
  //     {
  //       name: "方案设置",
  //       path: "/setting/programme",
  //       index: "8-5"
  //     },
  //     {
  //       name: "决策设置",
  //       path: "/setting/policySet",
  //       index: "8-6"
  //     }
  //   ]
  // },
  {
    name: "更新记录",
    path: "/updateRecord",
    index: "9",
    icon: "el-icon-date"
  },
  {
    name: "操作手册",
    path: "/manual",
    index: "10",
    icon: "el-icon-document"
  },
];

export default {
  list
};
